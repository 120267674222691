'use client';

import { useEffect } from 'react';

import { usePathname } from 'next/navigation';

import { analyticsClient } from '../lib/client';

export default function AnalyticsProvider({ children }: Readonly<{ children: React.ReactNode }>) {
    const pathname = usePathname();
    useEffect(() => {
        analyticsClient.init();
    }, []);
    useEffect(() => {
        analyticsClient.trackPageView();
    }, [pathname]);
    return <>{children}</>;
}
