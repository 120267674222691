'use client';

import { ChakraProvider } from '@chakra-ui/react';
import '@fontsource/rubik/400.css';
import '@fontsource/rubik/500.css';
import '@fontsource/fira-sans/700.css';
import '@fontsource/fira-sans/500.css';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import ToastContainer from '@components/Toast/ToastContainer';
import AnalyticsProvider from '@modules/analytics/components/AnalyticsProvider';
import { persistor, store } from '@store/store';
import { theme } from '@theme/theme';
import { toastOptions } from '@utils/config-global';

export function Providers({ children }: Readonly<{ children: React.ReactNode }>) {
    return (
        <AnalyticsProvider>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <ChakraProvider
                        theme={theme}
                        toastOptions={{ defaultOptions: { ...toastOptions } }}
                    >
                        <ToastContainer>{children}</ToastContainer>
                    </ChakraProvider>
                </PersistGate>
            </Provider>
        </AnalyticsProvider>
    );
}
