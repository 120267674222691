import mixpanel from 'mixpanel-browser';

import { AnalyticsClient } from '../types/client';

class MixpanelClient implements AnalyticsClient {
    // eslint-disable-next-line class-methods-use-this
    init(): void {
        mixpanel.init(
            // TODO Environment-ize this!
            '8a888cf3a95ebfd8319cd3a8de5d779b',
            {
                persistence: 'localStorage',
            }
        );
    }

    // eslint-disable-next-line class-methods-use-this
    trackPageView(): void {
        mixpanel.track_pageview();
    }
}

export { MixpanelClient };
